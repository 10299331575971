import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

export type CrmUserInfo = {
  given_name: string;
  family_name: string;
  email: string;
};

const complianceUsers = [
  'thim@flowyour.money',
  'niels@flowyour.money',
  'andre@flowyour.money',
  'daan@flowyour.money',
  'danny@flowyour.money',
  'jort@flowyour.money',
];

export const useCrmUser = () => {
  const [user, setUser] = useState<CrmUserInfo | null>(null);
  const isCompliance = user?.email && complianceUsers.includes(user.email);

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setUser(data.attributes);
    });
  }, []);

  return { crmUser: user, isCompliance };
};
